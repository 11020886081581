var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "apply-list" }, [
    _c(
      "div",
      { staticClass: "apply-list-table" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.filterTableData,
              border: "",
              height: "668",
              "tooltip-effect": "light",
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "name",
                align: "center",
                "show-overflow-tooltip": true,
                "min-width": "200",
                label: "部门名称",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "jobCount",
                align: "center",
                "min-width": "200",
                label: "客户名称",
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (scope) {
                    return [
                      _c("select-popover", {
                        attrs: {
                          enableSearch: true,
                          enableCount: true,
                          selectItem: _vm.customerNameSearchGroupedItems,
                          label: "customerNames",
                          title: "客户名称",
                          maxSelect: 5,
                        },
                        on: { selectChange: _vm.selectDataChange },
                      }),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "light",
                            content: scope.row.customerName,
                            placement: "top",
                            "open-delay": 300,
                            disabled: _vm.tooltipDisabled,
                          },
                          nativeOn: {
                            mouseenter: function ($event) {
                              return _vm.handleShowTooltip($event)
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(scope.row.customerName))])]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { align: "center", "min-width": "200", label: "申请状态" },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function (scope) {
                    return [
                      _c("select-popover", {
                        attrs: {
                          enableSearch: true,
                          enableCount: true,
                          selectItem: _vm.statusSearchGroupedItems,
                          label: "status",
                          title: "申请状态",
                          maxSelect: 3,
                        },
                        on: { selectChange: _vm.selectDataChange },
                      }),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.statusMap[scope.row.status])),
                      ]),
                      scope.row.status == 2
                        ? _c(
                            "button",
                            {
                              staticClass: "view-reason",
                              on: {
                                click: function ($event) {
                                  return _vm.viewNoPassReason(scope.row)
                                },
                              },
                            },
                            [_vm._v("查看原因")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { align: "center", "min-width": "200", label: "申请时间" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            scope.row.created
                              ? scope.row.created.slice(0, 10)
                              : ""
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "auditName",
                align: "center",
                "min-width": "200",
                label: "审批人",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.audits.length == 0 ? [_vm._v("-")] : _vm._e(),
                      scope.row.audits.length == 1
                        ? [
                            _c("avatar", {
                              staticClass: "user-avatar",
                              attrs: {
                                size: "sm",
                                placement: "top",
                                userId: scope.row.audits[0].id,
                                enableCard: true,
                                enableLink: true,
                                enableRefAvatar: false,
                                enableName: true,
                                needMove: false,
                                realName: scope.row.audits[0].name,
                              },
                            }),
                          ]
                        : _vm._e(),
                      scope.row.audits.length > 1
                        ? [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  "popper-class":
                                    "user-list-popover-" + scope.$index,
                                  placement: "bottom",
                                  title: "",
                                  width: "320",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "user-avatar-list" },
                                  [
                                    _vm._l(
                                      scope.row.audits,
                                      function (audit, index) {
                                        return [
                                          index != 0
                                            ? _c(
                                                "i",
                                                { key: index + audit.id },
                                                [_vm._v("、")]
                                              )
                                            : _vm._e(),
                                          _c("avatar", {
                                            key: audit.id,
                                            staticClass: "user-avatar",
                                            attrs: {
                                              size: "sm",
                                              placement: "top",
                                              userId: audit.id,
                                              enableCard: true,
                                              enableLink: true,
                                              enableRefAvatar: false,
                                              enableName: true,
                                              needMove: false,
                                              parentPopoverClass:
                                                "user-list-popover-" +
                                                scope.$index,
                                              realName: audit.name,
                                            },
                                          }),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-ellipsis",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._l(
                                      scope.row.audits,
                                      function (audit, index) {
                                        return [
                                          index != 0
                                            ? _c(
                                                "i",
                                                { key: index + audit.id },
                                                [_vm._v("、")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              key: audit.id,
                                              staticClass: "project-info-item",
                                            },
                                            [_vm._v(_vm._s(audit.name))]
                                          ),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("template", { slot: "empty" }, [
              _c("div", [
                _c("span", { staticClass: "empty-img" }),
                _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
              ]),
            ]),
          ],
          2
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "审核不通过详情",
              visible: _vm.noPassReasonDialogVisible,
              width: "400px",
              "before-close": _vm.handleClose,
            },
            on: {
              "update:visible": function ($event) {
                _vm.noPassReasonDialogVisible = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: { model: _vm.form, "label-width": "80px" },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "原因" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", disabled: "" },
                      model: {
                        value: _vm.form.reason,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "reason", $$v)
                        },
                        expression: "form.reason",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "详细说明" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", disabled: "" },
                      model: {
                        value: _vm.form.desc,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "desc", $$v)
                        },
                        expression: "form.desc",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "审批人" } },
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", disabled: "" },
                      model: {
                        value: _vm.form.approver,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "approver", $$v)
                        },
                        expression: "form.approver",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.handleClose },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }