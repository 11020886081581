<template>
    <div class="apply-list">
        <div class="apply-list-table">
            <el-table
                v-loading="loading"
                :data="filterTableData"
                border
                style="width: 100%"
                height="668"
                tooltip-effect="light">
                <el-table-column
                    prop="name"
                    align="center"
                    :show-overflow-tooltip="true"
                    min-width="200"
                    label="部门名称"
                ></el-table-column>
                <el-table-column
                    prop="jobCount"
                    align="center"
                    min-width="200"
                    label="客户名称"
                >
                    <template slot="header" slot-scope="scope">
                        <select-popover
                            :enableSearch="true"
                            :enableCount="true"
                            :selectItem="customerNameSearchGroupedItems"
                            :label="'customerNames'"
                            :title="'客户名称'"
                            :maxSelect="5"
                            @selectChange="selectDataChange"
                        ></select-popover>
                    </template>
                    <template slot-scope="scope">
                        <el-tooltip
                            effect="light"
                            :content="scope.row.customerName"
                            placement="top"
                            :open-delay="300"
                            :disabled="tooltipDisabled"
                            @mouseenter.native ="handleShowTooltip($event)"
                        >
                            <!-- <span
                                class="customer-detail-link"
                                @click.prevent="handleJumpDetail(scope.row, 'customer')"
                            >
                                {{scope.row.customerName}}
                            </span> -->
                            <span>{{scope.row.customerName}}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    min-width="200"
                    label="申请状态"
                >
                    <template slot="header" slot-scope="scope">
                        <select-popover
                            :enableSearch="true"
                            :enableCount="true"
                            :selectItem="statusSearchGroupedItems"
                            :label="'status'"
                            :title="'申请状态'"
                            :maxSelect="3"
                            @selectChange="selectDataChange"
                        ></select-popover>
                    </template>
                    <template slot-scope="scope">
                        <span>{{statusMap[scope.row.status]}}</span>
                        <button class="view-reason" v-if="scope.row.status == 2" @click="viewNoPassReason(scope.row)">查看原因</button>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    min-width="200"
                    label="申请时间"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.created ? scope.row.created.slice(0, 10) : ''}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="auditName"
                    align="center"
                    min-width="200"
                    label="审批人"
                >
                    <template slot-scope="scope">
                        <template v-if="scope.row.audits.length == 0">-</template>
                        <template v-if="scope.row.audits.length == 1">
                            <avatar
                                class="user-avatar"
                                size="sm"
                                placement="top"
                                :userId="scope.row.audits[0].id"
                                :enableCard="true"
                                :enableLink="true"
                                :enableRefAvatar="false"
                                :enableName="true"
                                :needMove="false"
                                :realName="scope.row.audits[0].name"
                            ></avatar>
                        </template>
                        <!-- <template v-if="scope.row.audits.length > 1 && !useAuditPopover">
                            <span @mouseenter="handleTextMouseEnter" class="user-avatar-list">
                                <template v-for="(audit, index) in scope.row.audits">
                                    <i :key="index + audit.id" v-if="index != 0">、</i>
                                    <avatar
                                        :key="audit.id"
                                        class="user-avatar"
                                        size="sm"
                                        placement="top"
                                        :userId="audit.id"
                                        :enableCard="true"
                                        :enableLink="true"
                                        :enableRefAvatar="false"
                                        :enableName="true"
                                        :needMove="false"
                                        :parentPopoverClass="'user-list-popover-' + scope.$index"
                                        :realName="audit.name"
                                    ></avatar>
                                </template>
                            </span>
                        </template> -->
                        <template v-if="scope.row.audits.length > 1">
                            <el-popover
                                :popper-class="'user-list-popover-' + scope.$index"
                                placement="bottom"
                                title=""
                                width="320"
                                trigger="hover"
                            >
                                <div class="user-avatar-list">
                                    <template v-for="(audit, index) in scope.row.audits">
                                        <i :key="index + audit.id" v-if="index != 0">、</i>
                                        <avatar
                                            :key="audit.id"
                                            class="user-avatar"
                                            size="sm"
                                            placement="top"
                                            :userId="audit.id"
                                            :enableCard="true"
                                            :enableLink="true"
                                            :enableRefAvatar="false"
                                            :enableName="true"
                                            :needMove="false"
                                            :parentPopoverClass="'user-list-popover-' + scope.$index"
                                            :realName="audit.name"
                                        ></avatar>
                                    </template>
                                </div>
                                <span slot="reference" class="text-ellipsis">
                                    <template v-for="(audit, index) in scope.row.audits">
                                        <i :key="index + audit.id" v-if="index != 0">、</i>
                                        <span class="project-info-item" :key="audit.id">{{audit.name}}</span>
                                    </template>
                                </span>
                            </el-popover>
                        </template>
                        <!-- <span>{{scope.row.auditName || '-'}}</span> -->
                    </template>
                </el-table-column>
                <template slot="empty">
                    <div>
                        <span class="empty-img"></span>
                        <p class="empty-data">暂无数据</p>
                    </div>
                </template>
            </el-table>

            <el-dialog
                title="审核不通过详情"
                :visible.sync="noPassReasonDialogVisible"
                width="400px"
                :before-close="handleClose">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="原因">
                        <el-input type="textarea" v-model="form.reason" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="详细说明">
                        <el-input type="textarea" v-model="form.desc" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="审批人">
                        <el-input type="textarea" v-model="form.approver" disabled></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="handleClose">确定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import DepartmentService from '#/js/service/departmentService.js';
import SelectPopover from '#/component/common/select-popover.vue';
import Avatar from '#/component/common/avatar-new.vue';
export default {
    name: "DepartmentApplyList",
    components: {
        SelectPopover,
        Avatar
    },
    data() {
        return {
            noPassReasonDialogVisible: false,
            loading: false,
            name: "",
            tableData: [],
            filterTableData: [],
            customerNameSearchGroupedItems: [],
            statusSearchGroupedItems: [],
            customerFilter: [],
            statusFilter: [],
            tooltipDisabled: true,
            statusMap: ['待审核', '审核通过', '未通过'],
            form: {
                reason: '',
                desc: '',
                approver: '',
            }
        }
    },
    computed: {
        isAdministrator() {
            return this.$store.state.user.userInfo.isAdministrator || false;
        },
        privilegeCodeList() {
            return this.$store.state.user.userInfo.privilegeCodeList || [];
        },
        isDepartmentManageAudit() {
            return this.privilegeCodeList.includes('DepartmentReviewer') ||
                this.privilegeCodeList.includes('DepartmentManageAudit');
        },
    },
    mounted() {
        if(!this.isAdministrator && !this.isDepartmentManageAudit){
            shortTips("权限不足，请联系管理员")
            sessionStorage.setItem('tabItems', JSON.stringify([]));
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }

        this.getList();
    },
    methods: {
        viewNoPassReason(row) {
            if(row.rejectResean == 1){
                this.form.reason = '重复申请，已存在该部门';
            } else if(row.rejectResean == 2) {
                this.form.reason = '信息有误，请重新确认信息';
            } else if(row.rejectResean == 3) {
                this.form.reason = '其他';
            } else {
                this.form.reason = '';
            }
            this.form.desc = row.auditDetail;
            let audits = row.audits.map((el) => {
                return el.realName+'@'+el.nickname;
            })
            this.form.approver = audits.join();

            this.noPassReasonDialogVisible = true;
        },
        handleClose() {
            this.noPassReasonDialogVisible = false;
        },
        getList() {
            this.loading = true;
            DepartmentService.getApplyList().then(res => {
                this.loading = false;
                this.tableData = res || [];
                this.getCustomerNameFilter();
                this.getStatusFilter();
                this.getFilterList();
            }).catch(err => {
                this.loading = false;
            })
        },
        getCustomerNameFilter() {
            let customerNameFilter = {}, list = [];
            this.tableData.forEach(item => {
                if(customerNameFilter[item.customerName]) {
                    customerNameFilter[item.customerName].count++;
                } else {
                    customerNameFilter[item.customerName] = {
                        label: item.customerName,
                        isSelect: this.customerFilter.indexOf(item.customerName) > -1 ? true : false,
                        count: 1
                    };
                }
            });
            Object.keys(customerNameFilter).forEach(item => {
                list.push(customerNameFilter[item]);
            })
            list.sort((item1, item2) =>{
                return (item2.count - item1.count);
            });
            this.customerNameSearchGroupedItems = list;
        },
        getStatusFilter() {
            let statusFilter = {
                0: {
                    label: '待审核',
                    isSelect: false,
                    count: 0
                },
                1: {
                    label: '审核通过',
                    isSelect: false,
                    count: 0
                },
                2: {
                    label: '未通过',
                    isSelect: false,
                    count: 0
                }
            };
            let list = []
            this.tableData.forEach(item => {
                statusFilter[item.status].count++;
            });
            Object.keys(statusFilter).forEach(item => {
                list.push(statusFilter[item]);
            })
            list.sort((item1, item2) =>{
                return (item2.count - item1.count);
            });
            this.statusSearchGroupedItems = list;
        },
        selectDataChange(label, result) {
            let list = [];
            result.forEach(item => {
                list.push(item.label);
            });
            if(label == 'customerNames') {
                this.customerFilter = list;
            }
            if(label == 'status') {
                this.statusFilter = list;
            }
            this.getFilterList();
        },
        getFilterList() {
            if(this.customerFilter.length > 0 || this.statusFilter.length > 0) {
                let list = [];
                if(this.customerFilter.length > 0) {
                    this.tableData.forEach(item => {
                        if(this.customerFilter.indexOf(item.customerName) > -1) {
                            list.push(item);
                        }
                    })
                    if(this.statusFilter.length > 0) {
                        list = list.filter(item => {
                            return this.statusFilter.indexOf(this.statusMap[item.status]) > -1;
                        })
                    }
                }else {
                    if(this.statusFilter.length > 0) {
                        list = this.tableData.filter(item => {
                            return this.statusFilter.indexOf(this.statusMap[item.status]) > -1;
                        })
                    }
                }
                this.filterTableData = list;
            } else {
                this.filterTableData = this.tableData;
            }
        },
        handleShowTooltip(event){
            let cell = event.target;
            setTimeout(() => {
                this.tooltipDisabled = cell.clientWidth == cell.scrollWidth;
            }, 300);
        },
        handleTextMouseEnter(e) {
            const ele = e.target;
            const range = document.createRange();
            range.setStart(ele, 0);
            range.setEnd(ele, ele.childNodes.length);
            const rangeWidth = range.getBoundingClientRect().width;
            if ((rangeWidth > ele.offsetWidth || ele.scrollWidth > ele.offsetWidth)) {
                this.useAuditPopover = true;
            }
        },
    }
}
</script>

<style lang="scss" scope>
    .apply-list {
        height: 100%;
        min-height: 500px;
        padding: 10px 20px 100px 20px;
        background: url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
        display: flex;
        flex-direction: column;

        .apply-list-table {
            height: 60.6% !important;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            letter-spacing: 0px;
            // height: 100%;
            .el-table {
                background-color: transparent;
                .el-table__body-wrapper {
                    background-color: #fff;
                }
              
                th {
                    padding: 0;
                    height: 42px;
                    line-height: 42px;
                    background-color: #E8E8E8;
                    border-color: #CCC;
                    color: #666;
                }
                .job-category-operate {
                    .text-operate-btn + .text-operate-btn {
                        margin-left: 20px;
                    }
                }
                .el-table__empty-block {
                    margin: 0 auto;
                    
                    .el-table__empty-text {
                        line-height: inherit;
                    }
                    .empty-img {
                        display: inline-block;
                        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                        width: 240px;
                        height: 228px;
                    }
                    .empty-data {
                        font-size: 16px;
                        line-height: 21px;
                        margin-bottom: 0px;
                    }
                }
            }

            .view-reason {
                border: 1px solid #ccc;
                margin-left: 10px;
                padding: 3px 8px;
                color: #666;
                font-size: 12px;
                border-radius: 4px;
            }
        }
    }
</style>
<style lang="scss">
.user-avatar-list .user-avatar {
    display: inline-block;
}
.user-avatar-list-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>